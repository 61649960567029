<template lang="pug">
include ../../../configs/template
form(@submit.prevent="changeFullName").row.mx-2.mt-2
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.last_name_ukr', 'lastNameUK', '["required", "alphaUA"]')
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.first_name_ukr', 'firstNameUK', '["required", "alphaUA"]')
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.middle_name_ukr', 'middleNameUK', '["alphaUA"]')
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.last_name_eng', 'lastNameEN', '["required", "alphaEN"]')
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.first_name_eng', 'firstNameEN', '["required", "alphaEN"]')
  div.col-sm-12.col-md-6.col-lg-4
    +date-picker-validation('body.change_date', 'dateModified','dateModifiedObject','["required","maxValue"]')(:max="new Date().toISOString()")
  div.col-sm-12(v-if="checkAccess('sailorFullNameChanges', 'add_file')")
    FileDropZone(ref="mediaContent")
  div.col-sm-12.text-center
    v-btn(color="success" type="submit" :loading="buttonLoader") {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { sailorChangeFullname } from '@/mixins/validationRules'

const initBody = () => (
  {
    last_name_ukr: null,
    first_name_ukr: null,
    middle_name_ukr: null,
    last_name_eng: null,
    first_name_eng: null,
    change_date: null
  }
)

export default {
  name: 'SailorFullNameChangesAdd',
  data () {
    return {
      body: initBody(),
      buttonLoader: false,
      checkAccess
    }
  },
  props: {
    getDocuments: { type: Function, default: () => {} },
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  computed: {
    ...mapState({
      id: (state) => state.sailor.sailorId,
      lang: (state) => state.main.lang
    }),
    dateModifiedObject () {
      return this.body.change_date ? new Date(this.body.change_date) : null
    }
  },
  validations () { return sailorChangeFullname(this._data.body) },
  mounted () {
    Object.keys(this.body).forEach(el => (this.body[el] = this.sailorDocument[el]))
  },
  methods: {
    ...mapActions(['setChangeSailorFullname', 'updateChangeSailorFullname']),

    /** Change full name */
    async changeFullName () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.buttonLoader = true
      const { documentID } = this.$route.params
      let data = {
        ...this.$route.params,
        body: {
          sailor: this.id,
          ...clearBody(this.body)
        }
      }
      if (checkAccess('sailorFullNameChanges', 'add_file')) {
        data.media = {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'old_name'
        }
      }
      const response = await this[`${documentID ? 'update' : 'set'}ChangeSailorFullname`](data)
      if (response) {
        !documentID && (this.$parent.isViewAddSlot = false)
        this.body = initBody()
        this.$v.$reset()
      }
      this.buttonLoader = false
    }
  }
}
</script>
